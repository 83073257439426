$steel: #666666;
$steel-gray: #434349;
$gun-powder: #464457;
$lynch: #6c7293;
$cadet-blue: #a7abc3;
$alto: #d9d9d9;
$catskill-white: #f2f5f8;
$light-blue: #e6f7ff;
$columbia-blue: #91d5ff;
$picton-blue: #40a9ff;
$dodger-blue: #178ffe;
$ultramarine-blue: #5d78ff;
$melrose: #bec8fb;
$white-lilac: #e8e8e8;
