@import './font.scss';
@import './color.scss';
@import '../styles/font.scss';

body {
  margin: 0;
  padding: 0;
  // global font PingFang SC
  @include FontCN;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/**
 * overwrite antd 
 */

// Statistic number font to Poppins
.ant-statistic-content-value-int {
  @include FontNum;
}

// Layout
.ant-layout {
  background: $catskill-white;
}

// Table tbody
.ant-table-tbody {
  background: white;
}

// rmb style
.ant-statistic-content-prefix span {
  @include FontCN;
}

.ant-table-expanded-row {
  background: white !important;
  height: 0 !important;
  overflow: hidden;
}

.related-commodity {
  .highlight {
    color: #1890ff !important;
  }
}

.left-table {
  table {
    thead {
      .ant-table-selection-column {
        .ant-table-header-column {
          visibility: hidden;
        }
      }
    }
  }
}
